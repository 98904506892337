import { lazy } from "react";
import { Route } from "react-router-dom/cjs/react-router-dom";
import MedicineWindow from "../../app/patient/medicine/MedicineWindow";
const Appointments = lazy(() => import("../../app/appointment"));
const Settings = lazy(() => import("../../app/appsettings"));
const Billing = lazy(() => import("../../app/billing"));
const Dashboards = lazy(() => import("../../app/dashboards"));
const Inventory = lazy(() => import("../../app/inventory"));
const Patient = lazy(() => import("../../app/patient"));

export const MainRoutes = [
  // {
  //   name: "dashboards",
  //   route: <Route path="/dashboard" component={Dashboards} />,
  // },
  { name: "patients", route: <Route path="/patient" component={Patient} /> },
  { name: "pharmacy", route: <Route path="/pharmacy" component={MedicineWindow} /> },
  {
    name: "appointments",
    route: <Route path="/appointment" component={Appointments} />,
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
  },
  {
    name: "appSettings",
    route: <Route path="/settings" component={Settings} />,
  },
  { name: "billing", route: <Route path="/billing" component={Billing} /> },
];

export const PatientRoutes = [
  {
    name: "dashboards",
    route: <Route path="/dashboard" component={Dashboards} />,
  },
  { name: "patients", route: <Route path="/patient" component={Patient} /> },
  {
    name: "appointments",
    route: <Route path="/appointment" component={Appointments} />,
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
  },
  {
    name: "appSettings",
    route: <Route path="/settings" component={Settings} />,
  },
  { name: "billing", route: <Route path="/billing" component={Billing} /> },
];

export const SettingsRoutes = [
  {
    name: "dashboards",
    route: <Route path="/dashboard" component={Dashboards} />,
  },
  { name: "patients", route: <Route path="/patient" component={Patient} /> },
  {
    name: "appointments",
    route: <Route path="/appointment" component={Appointments} />,
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
  },
  {
    name: "appSettings",
    route: <Route path="/settings" component={Settings} />,
  },
  { name: "billing", route: <Route path="/billing" component={Billing} /> },
];
