import React, { Fragment, useState } from "react";
import { Slider } from "react-burgers";
import cx from "classnames";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { useTheme } from "../../app";

function AppMobileMenu(props) {
  const [active, setActive] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] =
    useState(false);

  const options = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  let { themeOptions, setThemeOptions } = useTheme();

  const toggleMobileSidebar = () => {
    setThemeOptions({
      ...themeOptions,
      enableMobileMenu: !themeOptions.enableMobileMenu,
    });
  };

  const toggleMobileSmall = () => {
    setThemeOptions({
      ...themeOptions,
      enableMobileMenuSmall: !themeOptions.enableMobileMenuSmall,
    });
  };

  return (
    <Fragment>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Slider
            width={26}
            lineHeight={2}
            lineSpacing={5}
            color="#6c757d"
            active={options.active}
            onClick={() => setActive(!active)}
          />
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button
            size="sm"
            className={cx("btn-icon btn-icon-only", {
              active: activeSecondaryMenuMobile,
            })}
            color="primary"
            onClick={() =>
              setActiveSecondaryMenuMobile(
                !this.state.activeSecondaryMenuMobile
              )
            }
          >
            <div className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </Button>
        </span>
      </div>
    </Fragment>
  );
}

export default AppMobileMenu;
