import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import App from "./app";
import { Toaster } from "react-hot-toast";
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <HashRouter>
      <Toaster position="top-center" />
      <Component />
    </HashRouter>,
    rootElement
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept("./app", () => {
    const NextApp = require("./app").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
