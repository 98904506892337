import React, { Fragment, useState } from "react";
import Slider from "react-slick";
import bg3 from "../../assets/utils/images/originals/citynights.jpg";
import bg1 from "../../assets/utils/images/originals/Mockup.png";

import bg4 from "../../assets/utils/images/originals/ayurveda-img.png";

import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withFormik, Field, Formik } from "formik";
import * as Yup from "yup";
import { signUpToApp } from "../../dataApi/login";
import toast from "react-hot-toast";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Loader from "react-loaders";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  gender: Yup.string().required("Gender is required"),
  contactNumber: Yup.string().required("Contact Number is required"),
  subscriptionId: Yup.string().required("Subscription ID is required"),
});

function SignupPage(props) {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    signUpToApp(values)
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.access_token) {
          toast.success("Signup success! Redirecting to login");
          localStorage.setItem("token", res.data.access_token);
          setTimeout(() => {
            history.push(`/login`);
          }, 1000);

          // setAuthFailed(false);
        } else {
          toast.error(`Login failed! failed to get access token`);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "ERR_BAD_REQUEST") {
          toast.error(`Signup failed! ${err.response.data.message}`);
        } else {
          toast.error(`Signup failed! ${err}`);
        }
      });
    setSubmitting(false);
  };

  return (
    <Fragment>
      <LoadingOverlayWrapper
        tag="div"
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#fff",
            opacity: 0.5,
          }),
        }}
        spinner={<Loader color="green" active type="line-scale" />}
      >
        <div className="h-100">
          <Row className="h-100 g-0">
            <Col
              lg="6"
              md="12"
              className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
            >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4>
                  <div>Welcome,</div>
                  <span>
                    It only takes a{" "}
                    <span className="text-success">few seconds</span> to create
                    your account
                  </span>
                </h4>
                <div>
                  <Formik
                    initialValues={{
                      name: "",
                      username: "",
                      password: "",
                      confirmPassword: "",
                      gender: "",
                      dateOfBirth: "",
                      address: "",
                      contactNumber: "",
                      alternateContactNumber: "",
                      subscriptionId: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      handleSubmit,
                      errors,
                      touched,
                      values,
                      handleReset,
                      setValues,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="name">
                                <span className="text-danger">*</span> Name
                              </Label>

                              <Field
                                type="text"
                                name="name"
                                className="form-control"
                              />
                              {touched && touched.name && errors && (
                                <div className="text-danger">{errors.name}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleName">
                                {" "}
                                <span className="text-danger">*</span>Email
                              </Label>
                              <Field
                                type="text"
                                name="username"
                                className="form-control"
                              />
                              {touched && touched.username && errors && (
                                <div className="text-danger">
                                  {errors.username}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="examplePassword">
                                <span className="text-danger">*</span> Password
                              </Label>
                              <Field
                                type="password"
                                name="password"
                                className="form-control"
                              />
                              {touched && touched.password && errors && (
                                <div className="text-danger">
                                  {errors.password}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="examplePasswordRep">
                                <span className="text-danger">*</span> Confirm
                                password
                              </Label>
                              <Field
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                id="confirmPassword"
                                placeholder="Confirm Password here..."
                              />
                              {touched && touched.confirmPassword && errors && (
                                <div className="text-danger">
                                  {errors.confirmPassword}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="gender">
                                <span className="text-danger">*</span> Gender
                              </Label>
                              <Field
                                as="select"
                                name="gender"
                                className="form-control"
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Field>
                              {touched && touched.gender && errors && (
                                <div className="text-danger">
                                  {errors.gender}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="dob">Date of Birth</Label>
                              <Field
                                type="date"
                                name="dateOfBirth"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="dob">
                                <span className="text-danger">*</span>Contact
                                number
                              </Label>
                              <Field
                                type="text"
                                name="contactNumber"
                                className="form-control"
                              />
                              {touched && touched.contactNumber && errors && (
                                <div className="text-danger">
                                  {errors.contactNumber}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="dob">Alternate contact number</Label>
                              <Field
                                type="text"
                                name="alternateContactNumber"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="dob">Address</Label>
                              <Field
                                type="text"
                                name="address"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="dob">
                                <span className="text-danger">*</span>
                                Subscription Id
                              </Label>
                              <Field
                                type="text"
                                name="subscriptionId"
                                className="form-control"
                              />
                              {touched && touched.subscriptionId && errors && (
                                <div className="text-danger">
                                  {errors.subscriptionId}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4 d-flex align-items-center">
                          <h5 className="mb-0">
                            Already have an account?{" "}
                            <Link to="/login" className="text-primary">
                              Sign in
                            </Link>
                          </h5>
                          <div className="ms-auto">
                            <Button
                              color="primary"
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg"
                              onClick={handleSubmit}
                            >
                              Create Account
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Col>
            <Col lg="6" className="d-xs-none">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}
                    />
                    {/* <div className="slider-content">
                      <h4>
                        Patient Management, Inventory Management, Appointments
                        booking
                      </h4>
                      <p></p>
                    </div> */}
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </LoadingOverlayWrapper>
    </Fragment>
  );
}
export default SignupPage;
