import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PageTitle from "../../../layout/main/PageTitle";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCheck, faEye, faXmark } from "@fortawesome/free-solid-svg-icons";
import { deleteMedicine, fetchMyMedicineRequests } from "../../../dataApi/medicineWindowApi";
import toast from "react-hot-toast";
import { useClinic } from "../..";
import { Divider } from "@mui/material";
import CommonComponents from "../../common";
import AppSidebar from "../../../layout/sidebar";
import AppHeader from "../../../layout/header";

function MedicineWindow() {
  const [medicineRequestList, setMedicineRequestList] = useState([]);

  const { selectedClinic } = useClinic();


  useEffect(() => {
    if (selectedClinic) {
      fetchMedicineList();
    }
  }, [selectedClinic]);

  const fetchMedicineList = () => {
    if (selectedClinic) {
      fetchMyMedicineRequests(
        selectedClinic._id,
        (data) => {
          toast.success("Refreshed!");
          setMedicineRequestList(data);
        },
        (errorMessage) => {
          toast.error("Failed to fetch medicine requests: " + errorMessage);
          setMedicineRequestList([]);
        }
      );
    }
  }

  const handleDeleteMedicine = (id) => {
    deleteMedicine(
      id,
      (data) => {
        toast.success("Deleted!");
        fetchMedicineList();
      },
      (errorMessage) => {
        toast.error("Failed to delete medicine : " + errorMessage);
      }
    );
  }

  return (
    <Fragment>
      <CommonComponents />
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <TransitionGroup>
              <CSSTransition
                component="div"
                classNames="TabsAnimation"
                appear={true}
                timeout={1500}
                enter={false}
                exit={false}
              >
                <div>
                  <PageTitle
                    heading="Medicine window"
                    subheading="Medicine details for patients prescribed by doctor."
                    icon="pe-7s-note2 icon-gradient bg-tempting-azure"
                  />

                  <div className="d-flex flex-wrap">
                    {
                      medicineRequestList.map(mr =>
                        <Card
                          // className="my-2"
                          color="primary"
                          outline
                          style={{
                            // width: '20rem'
                          }}
                          className="d-flex flex-wrap m-1"
                        >
                          <CardHeader>
                            <div>{mr.patientName}</div> -
                            <div>+91-{mr.contactNumber}</div>

                          </CardHeader>
                          <CardBody>
                            <CardTitle tag="h5">
                              <Button outline color={"success"} disabled size="sm" className="ms-1">
                                {mr.complaint}
                              </Button>
                            </CardTitle>
                            <Divider />
                            <CardText className="mt-2">
                              {mr.prescriptionDetails.map(pd => <div className="d-flex flex-wrap  border-dashed">

                                <Button outline color={"info"} disabled size="sm" className="ms-1">
                                  {pd.name}
                                </Button>
                                <Button outline color={"secondary"} disabled size="sm" className="ms-1">
                                  {`${pd.dose.morning || 0} - ${pd.dose.afternoon || 0} - ${pd.dose.night || 0} - ${pd.dose.days || 0} Days`}
                                </Button>
                                <Button outline color={"primary"} disabled size="sm" className="ms-1">
                                  {`Total : ${((pd.dose.morning || 0) + (pd.dose.afternoon || 0) + (pd.dose.night || 0)) * (pd.dose.days || 0)}`}
                                </Button>

                              </div>
                              )}
                            </CardText>
                          </CardBody>
                          <CardFooter className="justify-content-between">
                            <Button color="warning" onClick={() => handleDeleteMedicine(mr._id)}>
                              Mark Done <FontAwesomeIcon icon={faCheck} className="ms-1" />
                            </Button>
                            <Button color="secondary" disabled outline>
                              bill here
                            </Button>
                          </CardFooter>
                        </Card>
                      )
                    }


                  </div>
                  {/* 
            <DataTable
              columns={columns}
              data={medicineRequestList}
              pagination
            /> */}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
    </Fragment>
  );
}

export default MedicineWindow;
