import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import MetisMenu from "react-metismenu";
import {
  DashboardNav,
  PatientsNav,
  PharmacistNav,
  ClinicNav,
  InventoryNav,
  BillingNav,
  SettingsNav,
  AppSettingsNav,
} from "./NavItems";
import { useTheme, useUserProfile } from "../../app";

function Navbar(props) {
  const { themeOptions, setThemeOptions } = useTheme();
  const { userProfile } = useUserProfile();

  const toggleMobileSidebar = () => {
    setThemeOptions({
      ...themeOptions,
      enableMobileMenu: !themeOptions.enableMobileMenu,
    });
  };

  if (!userProfile) {
    return null;
  }

  return (
    <Fragment>
      <MetisMenu
        content={DashboardNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      {((userProfile.accessRights && userProfile.accessRights.patients) ||
        userProfile.position === "SUPER_ADMIN" || userProfile.position === "ADMIN") && (
          <>
            <h5 className="app-sidebar__heading">Patient</h5>
            <MetisMenu
              content={PatientsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}
      {((userProfile.accessRights && userProfile.accessRights.pharmacy) ||
        userProfile.position === "SUPER_ADMIN" || userProfile.position === "ADMIN") && (
          <>
            <h5 className="app-sidebar__heading">Pharmacy</h5>
            <MetisMenu
              content={PharmacistNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

      {((userProfile.accessRights && userProfile.accessRights.clinics) ||
        userProfile.position === "SUPER_ADMIN" || userProfile.position === "ADMIN") && (
          <>
            <h5 className="app-sidebar__heading">Clinic</h5>
            <MetisMenu
              content={ClinicNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}

      {/* <h5 className="app-sidebar__heading">Clinic</h5>
      <MetisMenu content={InventoryNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      <MetisMenu content={BillingNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" /> */}

      {((userProfile.accessRights && userProfile.accessRights.appSettings) ||
        userProfile.position === "SUPER_ADMIN" || userProfile.position === "ADMIN") && (
          <>
            <h5 className="app-sidebar__heading">Settings</h5>
            <MetisMenu
              content={SettingsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}
      {userProfile.position === "SUPER_ADMIN" && (
        <MetisMenu
          content={AppSettingsNav}
          onSelected={toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      )}
    </Fragment>
  );
}

export default withRouter(Navbar);
