import React, { Fragment, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Select } from "../../components/input/SelectField";
import { useAuth, useClinic } from "..";
import { fetchMyClinics } from "../../dataApi/clinicApi";
import toast from "react-hot-toast";

export const CommonComponents = ({ match }) => {
  const { selectedClinic, setSelectedClinic, clinics, setClinics } =
    useClinic();
  const { token } = useAuth();

  useEffect(() => {
    fetchMyClinics(
      (data) => {
        setClinics(data);
      },
      (errorMessage) => {
        toast.error("Failed to fetch clinics: " + errorMessage);
      }
    );
  }, []);

  return (
    <Fragment>
      <Modal size="sm" isOpen={token && !selectedClinic && clinics && clinics.length > 0}>
        <ModalHeader>Select clinic</ModalHeader>
        <ModalBody>
          <Select
            className="mx-2"
            placeholder="Select clinic"
            options={[
              {value: null, label: "--Select--"},
              ...((clinics &&
                clinics.map((c) => {
                  return { value: JSON.stringify(c), label: c.name };
                })) ||
                []),
            ]}
            value={JSON.stringify(selectedClinic)}
            onChange={(event) =>
              setSelectedClinic(JSON.parse(event.target.value))
            }
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CommonComponents;
