import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain from "../layout/main/AppMain";
import ThemeOptions from "../layout/themeoptions";
import AppHeader from "../layout/header";
import AppSidebar from "../layout/sidebar";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import LoginPage from "./login/LoginPage";
import SignupPage from "./login/SignupPage";
import { ToastContainer, toast } from "react-toastify";
import { fetchProfile, fetchProfilePic } from "../dataApi/userApi";
import { fetchOptionTable } from "../dataApi/optionTableApi";
import Themeoptions from "../layout/themeoptions";
import sideBar6 from "../assets/utils/images/sidebar/city1.jpg";
import { fetchMyClinics } from "../dataApi/clinicApi";
import { OPTION_TABLES } from "../constants";

const ThemeOptionsContext = createContext();
export const useTheme = () => {
  return useContext(ThemeOptionsContext);
};

const AuthContext = createContext();
export const useAuth = () => {
  return useContext(AuthContext);
};

export const UserProfileContext = createContext();
export const useUserProfile = () => {
  return useContext(UserProfileContext);
};

export const UserProfilePicContext = createContext();
export const useUserProfilePic = () => {
  return useContext(UserProfilePicContext);
};

const OptionTableContext = createContext();
export const useOptionTable = () => {
  return useContext(OptionTableContext);
};

const ClinicContext = createContext();
export const useClinic = () => {
  return useContext(ClinicContext);
};

function Main(props) {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [userProfile, setUserProfile] = useState(null);
  const [userProfilePic, setUserProfilePic] = useState(null);

  const [selectedClinic, changeSelectedClinic] = useState(null);
  const [clinics, setClinics] = useState(null);
  const [optionTables, setOptionTables] = useState({});
  const [showThemeSetting, setShowThemeSetting] = useState(false);
  const [themeOptions, setThemeOptions] = useState({
    backgroundColor: "",
    headerBackgroundColor: "",
    enableMobileMenuSmall: "",
    enableBackgroundImage: false,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: "white",
    backgroundImage: sideBar6,
    backgroundImageOpacity: "opacity-06",
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true,
  });
  const contextValue = useMemo(
    () => ({
      token,
      setToken_,
    }),
    [token]
  );

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
      refreshProfile();
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    const storedClinic = sessionStorage.getItem("CLINIC");
    if (storedClinic) {
      changeSelectedClinic(JSON.parse(storedClinic));
    }
  }, []);

  const setSelectedClinic = (clinicValue) => {
    sessionStorage.setItem("CLINIC", JSON.stringify(clinicValue));
    changeSelectedClinic(clinicValue);
  };

  useEffect(() => {
    if (token) {
      refreshOptionTable();

      fetchMyClinics(
        (data) => {
          setClinics(data);
        },
        (errorMessage) => {
          toast.error("Failed to fetch clinics: " + errorMessage);
          setClinics([]);
        }
      );
    }
  }, [token]);

  const refreshOptionTable = () => {
    fetchOptionTable(
      null,
      (data) => {
        const mergedData = {};
        data.forEach(item => {
          const { name, options } = item;

          if (!mergedData[name]) {
            mergedData[name] = { ...item };
          } else {
            mergedData[name].options = [...mergedData[name].options, ...options];
          }
        });

        mergedData[OPTION_TABLES.MEDICINES] = {
          name: OPTION_TABLES.MEDICINES,
          options: []
        };

        if (mergedData && mergedData[OPTION_TABLES.TABLETS] && mergedData[OPTION_TABLES.TABLETS].options) {
          mergedData[OPTION_TABLES.MEDICINES].options = [...mergedData[OPTION_TABLES.MEDICINES].options, ...mergedData[OPTION_TABLES.TABLETS].options.map(op => { return { type: OPTION_TABLES.TABLETS, value: op } })];
        }
        if (mergedData && mergedData[OPTION_TABLES.CHURN] && mergedData[OPTION_TABLES.CHURN].options) {
          mergedData[OPTION_TABLES.MEDICINES].options = [...mergedData[OPTION_TABLES.MEDICINES].options, ...mergedData[OPTION_TABLES.CHURN].options.map(op => { return { type: OPTION_TABLES.CHURN, value: op } })];
        }
        if (mergedData && mergedData[OPTION_TABLES.SYRUP] && mergedData[OPTION_TABLES.SYRUP].options) {
          mergedData[OPTION_TABLES.MEDICINES].options = [...mergedData[OPTION_TABLES.MEDICINES].options, ...mergedData[OPTION_TABLES.SYRUP].options.map(op => { return { type: OPTION_TABLES.SYRUP, value: op } })];
        }
        if (mergedData && mergedData[OPTION_TABLES.COMPOSITION] && mergedData[OPTION_TABLES.COMPOSITION].options) {
          mergedData[OPTION_TABLES.MEDICINES].options = [...mergedData[OPTION_TABLES.MEDICINES].options, ...mergedData[OPTION_TABLES.COMPOSITION].options.map(op => { return { type: OPTION_TABLES.COMPOSITION, value: op } })];
        }

        setOptionTables(mergedData);
      },
      (err) => {
        //addError
      }
    );
  }

  const refreshProfile = () => {
    fetchProfile(
      (data) => {
        setUserProfile(data);
      },
      (err) => {
        //addError
      }
    );
    fetchProfilePic(
      (data) => {
        setUserProfilePic(data);
      },
      (err) => { }
    );
  };

  const getOptionTable = (optionsName) => {
    if (optionTables && optionTables[optionsName]) {
      return optionTables.filter(ot => ot.name === optionsName);
    }

  }

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = themeOptions;

  return (
    <ThemeOptionsContext.Provider
      value={{
        themeOptions: themeOptions,
        setThemeOptions: setThemeOptions,
        showThemeSetting,
        setShowThemeSetting,
      }}
    >
      <AuthContext.Provider value={contextValue}>
        <UserProfileContext.Provider value={{ userProfile, refreshProfile }}>
          <UserProfilePicContext.Provider value={userProfilePic}>
            <ClinicContext.Provider
              value={{ selectedClinic, setSelectedClinic, clinics, setClinics }}
            >
              <OptionTableContext.Provider
                value={{ optionTables, setOptionTables, getOptionTable, refreshOptionTable }}
              >
                <Themeoptions />
                <ResizeDetector
                  handleWidth
                  render={({ width }) => (
                    <Fragment>
                      <div
                        className={cx(
                          "app-container app-theme-" + colorScheme,
                          { "fixed-header": enableFixedHeader },
                          {
                            "fixed-sidebar": enableFixedSidebar || width < 1250,
                          },
                          { "fixed-footer": enableFixedFooter },
                          {
                            "closed-sidebar":
                              enableClosedSidebar || width < 1250,
                          },
                          {
                            "closed-sidebar-mobile":
                              closedSmallerSidebar || width < 1250,
                          },
                          { "sidebar-mobile-open": enableMobileMenu },
                          { "body-tabs-shadow-btn": enablePageTabsAlt }
                        )}
                      >
                        <Toaster position="top-center" />
                        <AppMain />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/signup" component={SignupPage} />
                      </div>
                      <ToastContainer />
                    </Fragment>
                  )}
                />
              </OptionTableContext.Provider>
            </ClinicContext.Provider>
          </UserProfilePicContext.Provider>
        </UserProfileContext.Provider>
      </AuthContext.Provider>
    </ThemeOptionsContext.Provider>
  );
}

export default withRouter(Main);
