export const OPTION_TABLES = {
  GENDER: "GENDER",
  FAMILY_RELATION: "FAMILY_RELATION",
  DISEASE: "DISEASE",
  SLEEP_QUALITY: "SLEEP_QUALITY",
  SLEEP_DURATION: "SLEEP_DURATION",
  MEDICINES: "MEDICINES",
  CHURN: "CHURN",
  COMPOSITION: "COMPOSITION",
  TABLETS: "TABLET",
  SYRUP: "SYRUP",
  THERAPIES: "THERAPIES",
  HABIT: "HABIT",
  POSITIONS: "POSITIONS",
  PAYMENT_STATUS: "PAYMENT_STATUS",
};
