import React from "react";
import cx from "classnames";
import { useTheme } from "../../app";
function PageTitle(props) {
  let { heading, icon, subheading } = props;
  const { themeOptions } = useTheme();

  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div
            className={cx("page-title-icon", {
              "d-none": !themeOptions.enablePageTitleIcon,
            })}
          >
            <i className={icon} />
          </div>
          <div>
            {heading}
            <div
              className={cx("page-title-subheading", {
                "d-none": !themeOptions.enablePageTitleSubheading,
              })}
            >
              {subheading}
            </div>
          </div>
        </div>
        {/* <div className="page-title-actions">{randomize(arr)}</div> */}
      </div>
    </div>
  );
}

export default PageTitle;
