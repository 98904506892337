import React, { Fragment } from "react";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import HeaderLogo from "../applogo";
import UserBox from "./components/UserBox";
import { useTheme } from "../../app";

function Header(props) {
  const { themeOptions } = useTheme();

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-header", themeOptions.headerBackgroundColor, {
            "header-shadow": themeOptions.enableHeaderShadow,
          })}
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <div>
            <HeaderLogo />
            <div
              className={cx("app-header__content", {
                "header-mobile-open": themeOptions.enableMobileMenuSmall,
              })}
            >
              <div className="app-header-left"></div>
              <div className="app-header-right">
                <UserBox />
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
export default Header;
