import React from "react";
import {
  FormGroup,
  Label,
  Input as StrapInput,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import withFormikField from "./FormikField";

export function InputField(props) {
  return (
    <FormGroup>
      {props.label && <Label for={props.name}>{props.label}</Label>}
      <InputGroup>
        {props.prependElement && (
          <InputGroupText addonType="prepend">
            {props.prependElement}
          </InputGroupText>
        )}
        <StrapInput
          type={props.type || "text"}
          name={props.name}
          id={props.id || props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          invalid={props.invalid}
          value={props.value}
          disabled={props.disabled}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default withFormikField(InputField);