import React, { useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import withFormikField from "./FormikField";
import _ from "lodash";
import { useOptionTable } from "../../app";

export function Select(props) {
  const { optionTables } = useOptionTable();
  useEffect(() => {
    if (props.onChange &&
      !props.value &&
      optionTables &&
      optionTables[props.optionTable] &&
      optionTables[props.optionTable].options &&
      optionTables[props.optionTable].options[0]
    ) {
      props.onChange({
        target: { name: props.name, value: optionTables[props.optionTable].options[0] },
      });
    }
  }, []);
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <InputGroup>
        {props.prependElement && (
          <InputGroupText addonType="prepend">
            {props.prependElement}
          </InputGroupText>
        )}
        <Input
          type="select"
          name={props.name}
          id={props.id || props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          invalid={props.invalid}
          value={props.value}
        >
          {optionTables &&
            optionTables[props.optionTable] &&
            optionTables[props.optionTable].options &&
            optionTables[props.optionTable].options.map((opt) => (
              <option value={opt}>{opt}</option>
            ))}
          {props.options &&
            props.options.map((opt) => (
              <option value={opt.value}>{opt.label}</option>
            ))}
        </Input>
      </InputGroup>
    </FormGroup>
  );
}

export default withFormikField(Select);
