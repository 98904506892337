import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Button, FormGroup, Input, FormFeedback } from "reactstrap";
import { loginToApp } from "../../dataApi/login";
import toast from "react-hot-toast";
import { useAuth } from "..";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Loader from "react-loaders";
import appLogo from "../../assets/utils/images/logo_small.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputField from "../../components/input/InputField";

const LoginPage = ({ match }) => {
  const { setToken_ } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleLogin = (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    loginToApp(values)
      .then((res) => {
        if (res.data && res.data.access_token) {
          toast.success("Login success!");
          setToken_(res.data.access_token);
          localStorage.setItem("token", res.data.access_token);
          history.push(`dashboard/main`);
        } else {
          toast.error(`Login failed! failed to get access token`);
        }
      })
      .catch((err) => {
        toast.error(`Login failed!`);
        setSubmitting(false);
        setErrors({ auth: "Invalid username or password" });
      }).finally(()=>{
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <LoadingOverlayWrapper
        tag="div"
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base, 
            background: "#fff",
            opacity: 0.5,
          }),
        }}
        spinner={<Loader color="green" active type="line-scale" />}
      >
        <div className="h-100 bg-plum-plate bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="h5 modal-title text-center">
                      <h4 className="mt-2">
                        <div className="app-logo-inverse mx-auto mb-3" />

                        <div>Welcome back,</div>
                        <span>Please sign in to your account below.</span>
                      </h4>
                    </div>

                    <Formik
                      initialValues={{ username: "", password: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.username) {
                          errors.username = "Required";
                        }
                        if (!values.password) {
                          errors.password = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={handleLogin}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <Row form>
                            <Col md={12}>
                              <InputField
                                type="email"
                                name="username"
                                id="exampleEmail"
                                placeholder="Username/email here..."
                                className="form-control" />

                            </Col>
                            <Col md={12}>
                              <InputField
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder="Password here..."
                                className="form-control" />
                            </Col>
                          </Row>
                          <ErrorMessage
                            name="auth"
                            component="div"
                            className="invalid-feedback"
                          />
                          <div className="divider" />
                          <h6 className="mb-0">
                            No account? <Link to="/signup">Sign up now</Link>
                          </h6>
                          <div className="modal-footer clearfix">
                            <div className="float-end">
                              <Button
                                color="primary"
                                size="lg"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Logging in..." : "Login"}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="text-center text-white opacity-8 mt-3">
                Copyright &copy; Ambica-VaidyaPlus 2023
              </div>
            </Col>
          </div>
        </div>
      </LoadingOverlayWrapper>
    </Fragment>
  );
};

export default LoginPage;
